import React from 'react';
import styled from 'styled-components';

import { BLUE } from '../constants/style';

const Highlight = styled.span`
  background-color: ${BLUE};
  height: 10px;
  width: 400%;
  position: absolute;
  z-index: -1;
  bottom: 3px;
  left: -300%;
`;

const TextContainer = styled.span`
  position: relative;
`;

const Title = ({ children, className }) => {
  return (
    <TextContainer className={className}>
      <Highlight />
      {children}
    </TextContainer>
  );
};

export default Title;
