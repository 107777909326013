import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { contactMethod } from '../constants/customFbEvents';
import { fbCustomEvent, fbEvent } from '../helpers/fbEvent';

const IconLink = styled.a`
  margin: 0 0.5em;
  text-decoration: none;
  cursor: pointer;
`;

const SocialIcon = ({ icon, className, href, contactType }) => {
  const handleClick = () => {
    fbCustomEvent(contactMethod(contactType));
    fbEvent('Contact');
  };

  return (
    <IconLink onClick={handleClick} className={className} href={href} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} />
    </IconLink>
  );
};

export default SocialIcon;
