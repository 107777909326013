import React, { useContext } from "react"
import { AppContext } from "../contexts/appContext"

const Button = ({ onClick, children, className = "", type, disabled }) => {
  const { setOverlay } = useContext(AppContext)
  const handleClick = () => setOverlay(true)

  return (
    <button
      disabled={disabled}
      type={type | "button"}
      className={`button ${className}`}
      onClick={onClick || handleClick}
    >
      {children}
    </button>
  )
}

export default Button
