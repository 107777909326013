export default {
  whatsUpUrl: 'https://wa.me/41794598748',
  email: 'irovichfit@gmail.com',
  instagram: 'https://www.instagram.com/i_irovich/',
  phone: '+41 79 459 8748',
  phoneUrl: '+41794598748',
  facebook: 'https://www.facebook.com/ilya.irovich.5',
  telegram: 'https://t.me/i_irovich',
  siteUrl: 'https://irovich-fit.com',
};
