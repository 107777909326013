import { navigate } from "gatsby"
import React, { useContext } from "react"

import locales from "../constants/locales"
import { AppContext } from "../contexts/appContext"

const LanguageSelector = ({ className }) => {
  const { locale, pagePath } = useContext(AppContext)
  const localesNames = Object.keys(locales).filter(loc => loc !== locale)
  const changeLocale = event => {
    if (event.target.value.toLocaleLowerCase() === "de") {
      navigate(`${pagePath}/de`)
    }

    if (event.target.value.toLocaleLowerCase() === "ru") {
      navigate(`${pagePath}/ru`)
    }

    if (event.target.value.toLocaleLowerCase() === "en") {
      navigate(`${pagePath}/`)
    }
  }
  return (
    <div className={`control has-icons-left ${className}`}>
      <div className="select">
        <select
          value={locale}
          onChange={changeLocale}
          id="LanguageSelector"
          className="has-text-grey-dark"
        >
          <option>{locale.toUpperCase()}</option>
          {localesNames.map(option => (
            <option key={option}>{option.toUpperCase()}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default LanguageSelector
