module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
  },
  de: {
    path: 'de',
    locale: 'Deutsch',
  },
  ru: {
    path: 'ru',
    locale: 'Russian',
  },
};
