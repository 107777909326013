import React, { useEffect } from "react"
import { IntlProvider } from "react-intl"

import {
  onlineWorkoutPageVisitByLocale,
  pageVisitByLocale,
} from "../constants/customFbEvents.js"
import { fbCustomEvent } from "../helpers/fbEvent.js"
import de from "../i18n/de.json"
import deOnlineWorkouts from "../i18n/de.online-workouts.json"
import enOnlineWorkouts from "../i18n/en.online-workouts.json"
import ruOnlineWorkouts from "../i18n/ru.online-workouts.json"
import en from "../i18n/en.json"
import ru from "../i18n/ru.json"

const messages = {
  en: { ...en, ...enOnlineWorkouts },
  de: { ...de, ...deOnlineWorkouts },
  ru: { ...ru, ...ruOnlineWorkouts },
}

const I18n = ({ locale, children, page }) => {
  useEffect(() => {
    if (locale && typeof locale === "string") {
      if (page === "/") {
        fbCustomEvent(pageVisitByLocale(locale.toUpperCase()))
      }
      if (page === "/online-workout") {
        fbCustomEvent(onlineWorkoutPageVisitByLocale(locale.toUpperCase()))
      }
    }
  }, [locale, page])
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  )
}

export default I18n
