import React, { useContext, useState } from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import logo from "../assets/logo.svg"
import LanguageSelector from "./LanguageSelector"
import Link from "gatsby-link"
import { AppContext } from "../contexts/appContext"

const Image = styled.img`
  width: 2.6em;
  margin-left: 0.75em;
  height: 52px;
`

const Navbar = () => {
  const { locale: activeLocale } = useContext(AppContext)
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!isOpen)
  const isOpenClass = isOpen ? "is-active" : ""
  const locale = activeLocale === "en" ? "" : activeLocale

  return (
    <nav
      id="mainNavbar"
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Image src={logo} alt="logo" />
        <span
          className="navbar-item is-family-secondary is-bold is-uppercase has-text-primary is-size-4 letter-spacing-3"
          href="/"
        >
          Ilya Irovich
        </span>

        <span
          role="button"
          onClick={toggle}
          className={`navbar-burger burger ${isOpenClass}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarCollapsible"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
      <div id="navbarCollapsible" className={`navbar-menu ${isOpenClass}`}>
        <div className="navbar-start has-text-right">
          <Link to={`/online-workout/${locale}`} className={"navbar-item"}>
            <FormattedMessage id={"Navbar.online"} />
          </Link>
          <Link to={`/${locale}#Home`} className={"navbar-item"}>
            <FormattedMessage id="Navbar.home" />
          </Link>
          <Link to={`/${locale}#About`} className={"navbar-item"}>
            <FormattedMessage id="Navbar.about" />
          </Link>
          <Link to={`/${locale}#Offers`} className={"navbar-item"}>
            <FormattedMessage id="Navbar.offer" />
          </Link>
          <Link to={`/${locale}#Testimonials`} className={"navbar-item"}>
            <FormattedMessage id="Navbar.testimonial" />
          </Link>
          <LanguageSelector className="navbar-item has-text-right" />
        </div>

        <div className="navbar-end" />
      </div>
    </nav>
  )
}

export default Navbar
