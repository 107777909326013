import React, { useState } from "react"
import moment from "moment"
import localizationDe from "moment/locale/de"
import localizationRu from "moment/locale/ru"
import localizationEn from "moment/locale/en-gb"

export const AppContext = React.createContext({
  locale: "en",
  overlay: false,
  pagePath: undefined,
})

const localizationMap = {
  en: localizationEn,
  de: localizationDe,
  ru: localizationRu,
}

export const AppContextProvider = ({ locale, children, pagePath }) => {
  moment.updateLocale(locale, localizationMap[locale])
  const [overlay, setOverlay] = useState(false)
  return (
    <AppContext.Provider value={{ locale, overlay, setOverlay, pagePath }}>
      {children}
    </AppContext.Provider>
  )
}
