import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import configs from '../configs';
import { ORANGE } from '../constants/style';
import AGB from '../files/Allgemeine Geschäftsbedingungen.pdf';
import dataProtection from '../files/Datenschutz.pdf';
import SocialsIcons from './SocialIcons';

const StyledHeart = styled.span`
  color: ${ORANGE};
  margin-right: 0.4em;
  margin-left: 0.4em;
`

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const PhoneLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`

const Footer = () => {
  return (
    <footer className="footer bg-blue">
      <div className="content">
        <div className="columns has-text-white is-size-7 is-centered has-text-centered-mobile has-text-weight-bold">
          <div className="column is-narrow">
            Ilya Irovich<StyledHeart>♥</StyledHeart>Sport |{" "}
            {moment().format("YYYY")}
          </div>
          <div className="column is-narrow">{configs.email}</div>
          <div className="column is-narrow">
            <PhoneLink href={`tel:${configs.phoneUrl}`}>
              {configs.phone}
            </PhoneLink>
          </div>
        </div>
        <div className="columns has-text-white is-size-7 is-centered has-text-centered-mobile has-text-weight-bold">
          <div className="column is-narrow">
            <a
              href={AGB}
              className="has-text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.terms-and-conditions" />
            </a>
          </div>
          <div className="column is-narrow">
            <a
              href={dataProtection}
              className="has-text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="Footer.data-protection" />
            </a>
          </div>
        </div>
        <IconContainer>
          <SocialsIcons />
        </IconContainer>
      </div>
    </footer>
  )
}

export default Footer
