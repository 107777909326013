export const fbEvent = (eventType) => {
  if (window && window.fbq) {
    window.fbq('track', eventType);
  }
};

export const fbCustomEvent = (eventType) => {
  if (window && window.fbq) {
    window.fbq('trackCustom', eventType);
  }
};
