import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

import configs from "../configs"

const Head = () => {
  const intl = useIntl()
  return (
    <Helmet>
      <html lang="en" />
      <meta httpEquiv="Content-Type" content="text/html; charset=ISO-8859-1" />
      <title>{intl.formatMessage({ id: "meta.title" })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: "meta.description" })}
      />
      <meta
        name="keywords"
        content="fitness, sport, zurich, personal training, personal instructor, gym"
      />

      {/* <!-- Schema.org markup for Google+ --> */}
      <meta
        itemProp="name"
        content={intl.formatMessage({ id: "meta.title" })}
      />
      <meta
        itemProp="description"
        content={intl.formatMessage({ id: "meta.description" })}
      />
      <meta itemProp="image" content={`${configs.siteUrl}/photo.jpg`} />

      {/*<!-- Twitter Card data -->*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@publisher_handle" />
      <meta
        name="twitter:title"
        content={intl.formatMessage({ id: "meta.title" })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({ id: "meta.description" })}
      />
      <meta name="twitter:creator" content="@author_handle" />
      {/*<!-- Twitter summary card with large image must be at least 280x150px -->*/}
      <meta name="twitter:image" content={`${configs.siteUrl}/photo.jpg`} />

      {/*<!-- Open Graph data -->*/}
      <meta
        property="og:title"
        content={intl.formatMessage({ id: "meta.title" })}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://irovich-fit.com" />
      <meta
        property="og:image"
        itemProp="image"
        content={`${configs.siteUrl}/photo.jpg`}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({ id: "meta.description" })}
      />
      <meta property="og:site_name" content="Ilya Irovich" />
      <meta property="og:updated_time" content="1572377022219" />

      <link rel="canonical" href="https://irovich-fit.com" />

      {/*PIXEL META*/}
      <meta
        name="facebook-domain-verification"
        content="orh6mn3r0l4lkcul5xktytoohlk6le"
      />

      {/* PIXEL */}
      <script>
        {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '590807031598915');
            fbq('track', 'PageView');
      `}
      </script>
      <script>
        {`
          if (window.netlifyIdentity) {
            window.netlifyIdentity.on("init", user => {
              if (!user) {
                window.netlifyIdentity.on("login", () => {
                  document.location.href = "/admin/";
                });
              }
            });
          }
        `}
      </script>
      <noscript>
        {`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=590807031598915&ev=PageView&noscript=1"
        />
        `}
      </noscript>
    </Helmet>
  )
}

export default Head
