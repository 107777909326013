import { faFacebookSquare, faInstagram, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment } from 'react';

import configs from '../configs';
import SocialIcon from '../elements/SocialIcon';

const SocialsIcons = () => {
  return (
    <Fragment>
      <SocialIcon className="navbar-item has-text-dark" icon={faFacebookSquare} href={configs.facebook} contactType="Facebook" />
      <SocialIcon className="navbar-item has-text-dark" icon={faInstagram} href={configs.instagram} contactType="Instagram" />
      <SocialIcon className="navbar-item has-text-dark" icon={faTelegram} href={configs.telegram} contactType="Telegram" />
      <SocialIcon className="navbar-item has-text-dark" icon={faWhatsapp} href={configs.whatsUpUrl} contactType="Whatsapp" />
      <SocialIcon className="navbar-item has-text-dark" icon={faEnvelope} href={`mailto:${configs.email}`} contactType="Email" />
    </Fragment>
  );
};

export default SocialsIcons;
